.warningContainer {
  margin-top: 12px;
  background: #fdf6ed;
  border-radius: 6px;
  padding: 16px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:12px;

  &.small {
    padding: 8px 12px;
  }

  &.noMargin {
    margin: 0;
  }


  .warningText {
    color: #d38217;
  }

  .action {
    color: #d38217;
    text-decoration: underline;
    cursor: pointer;
  }

  .icon {
    width: 22px;
    height: 19px;
  }

  .smallIcon {
    width: 16px;
    height: 14px;
  }

}

.successContainer {
    margin-top: 12px;
    background: rgba(0, 255, 0, 0.05);
    border-radius: 6px;
    padding: 16px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    &.small {
        padding: 8px 12px;
    }

    .successText {
      color: theme('colors.green');
    }

    .action {
        color: theme('colors.green');
        text-decoration: underline;
        cursor: pointer;
    }

    .icon {
      color: theme('colors.green');
        width: 22px;
        height: 19px;
    }

    .smallIcon {
      color: theme('colors.green');
        width: 16px;
        height: 14px;
    }
}


.errorContainer {
  margin-top: 12px;
  background: rgba(241, 34, 55, 0.05);
  border-radius: 6px;
  padding: 16px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  &.small {
    padding: 8px 12px;
  }

  .errorText {
    color: theme('colors.error-main');
  }

  .action {
    color: theme('colors.error-main');
    text-decoration: underline;
    cursor: pointer;
  }

  .icon {
    width: 22px;
    height: 19px;
  }

  .smallIcon {
    width: 16px;
    height: 14px;
  }

}
