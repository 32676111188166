.day {
  width: 42px;
  height: 42px;
  position: relative;
  outline: 1px solid rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
}

.pastDay {
  p {
    color: theme('colors.gray-300') !important;
  }

  span {
    color: theme('colors.gray-300') !important;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: theme('zIndex.above-base')
}

.disabledDay {
  background-color: theme('colors.gray-300');
}

.activeDay {
  cursor: pointer;
  outline: 1px solid theme('colors.gray-100');
  border: 1px solid theme('colors.gray-100');

  &:hover {
    outline: 1px solid theme('colors.divider');
    border: 1px solid theme('colors.divider');
    z-index: theme('zIndex.above-base')
  }
}

.reversedDay {
  background-color: theme('colors.primary-main');
}

.halfDayPickerDay {
  background-color: theme('colors.white');
}

.reversedDayText {
  span {
    color: theme('colors.white') !important;
  }

  p {
    color: theme('colors.white') !important;
  }
}

.middleDay {
  background-color: theme('colors.primary-main');

  span {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.triangleBase {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  width: 0;
  height: 0;
  z-index: theme('zIndex.above-base')
}

.triangleDisabledPm {
  border-bottom: 42px solid theme('colors.gray-300');
  border-left: 42px solid rgba(0, 0, 0, 0);
}

.triangleActivePm {
  border-bottom: 42px solid theme('colors.primary-main');
  border-left: 42px solid rgba(0, 0, 0, 0);
}

.triangleMiddlePm {
  border-bottom: 42px solid theme('colors.primary-main');
  border-left: 42px solid rgba(0, 0, 0, 0);
}

.triangleHalfDayPickerPm {
  border-bottom: 42px solid white;
  border-left: 42px solid rgba(0, 0, 0, 0);
}

.triangleDisabledAm {
  border-top: 42px solid theme('colors.gray-300');
  border-right: 42px solid rgba(0, 0, 0, 0);
}

.triangleActiveAm {
  border-top: 42px solid theme('colors.primary-main');
  border-right: 42px solid rgba(0, 0, 0, 0);
}

.triangleMiddleAm {
  border-top: 42px solid theme('colors.primary-main');
  border-right: 42px solid rgba(0, 0, 0, 0);
}

.triangleHalfDayPickerAm {
  border-top: 42px solid white;
  border-right: 42px solid rgba(0, 0, 0, 0);
}

.textBase {
  color: theme('colors.primary-main');
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  user-select: none;
}

.textLeft {
  position: absolute;
  left: 4px;
  top: 4px;
}

.textRight {
  position: absolute;
  right: 4px;
  bottom: 4px;
}

.textDisabled {
  color: theme('colors.gray-400')
}
