.titleSmall {
  br {
    display: inline-block;
  }
}

.titleLarge {
  br {
    display: none;
  }
}
